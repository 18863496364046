/**
 * h5调用原生 @author yujinbao
 */
export default {
  goBack(){
    if(window.webkit){
      window.webkit.messageHandlers.goBack.postMessage('');
    }else if(window.android){
      window.android.goBack()
    }
  },
  //调用原生收银台
  gotoPayment (payParams) {
    if(window.webkit){
      window.webkit.messageHandlers.gotoPayment.postMessage(payParams);
    }else if(window.android){
      window.android.gotoPayment(payParams);
    }
  },
  //获取用户信息
  getUserInfo () {
    if(window.webkit){
      window.webkit.messageHandlers.getUserInfo.postMessage('');
    }else if(window.android){
      window.android.getUserInfo();
    }
    
  },
  
}
