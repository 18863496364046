import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import openDoorList from '../views/openDoorList.vue'
import myexpress from '../views/myexpress.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  // base:'/qiannongyun/',
  routes:[
    {
      path: '/index',
      name: 'index',
      component: index
    },
    {
      path: '/openDoorList',
      name: 'openDoorList',
      component: openDoorList
    },
    {
      path: '/myexpress',
      name: 'myexpress',
      component: myexpress
    },
  ]
})

export default router
