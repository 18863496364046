<template>
  <div>
    <div class="top">
      <img @click="$router.back(-1)" src="../assets/img/back.png" alt="">
      <span>取件</span>
    </div>
    <div class="content" v-if=" openDoorDetails.length != 0 ">
      <div class="openDetails" v-for=" item in openDoorDetails ">
        <img v-if=" item.open_door_status == 1 " src="../assets/img/icon-success.jpg" alt="">
        <img v-if=" item.open_door_status == 0 " src="../assets/img/icon-error.jpg" alt="">
        <div class="d1">
          <p class="p1" v-if=" item.open_door_status == 1 ">开箱成功！</p>
          <p class="p1" v-if=" item.open_door_status == 0 ">系统超时！</p>
          <p class="p2">{{ item.company_name }}<span>{{ item.number }}</span>号</p>
          <p class="p2">{{ item.door_type }}</p>
          <p class="p3" v-if=" item.open_door_status == 1 ">若箱门异常，或误关箱门，您可点击【误关门重开】重新打开箱子。</p>
          <p class="p4" v-if=" item.open_door_status == 0 ">很抱歉，系统超时，请重试！</p>
        </div>
        <div class="d2">
          <p class="p1" v-if=" item.open_door_status == 1 " @click="openDoor(item.id)">误关门重开</p>
          <p class="p2" v-if=" item.open_door_status == 0 ">误关门重开</p>
        </div>
      </div>
      
    </div>
    <div class="sure">
      <div class="d2" @click="backEvent">完成</div>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'mint-ui';
  import { Indicator } from 'mint-ui';
  import native from '@/assets/js/native';
  // import $ from 'jquery'
  export default {
    name: 'openDoorList',
    data(){
      return{
        mobile: this.$route.query.receivePhone,
        company_id: this.$route.query.deviceNo,
        selectedList: this.$route.query.orderList,
        openDoorDetails:[],
      }
    },
    methods:{
      getOrderDetails(){
        let that = this;
        that.selectedList = that.selectedList.split(',');
        that.$Axios.post('api_cabinet/Deliverorder/queryDoorStatus',{
          mobile: that.mobile,
          company_id: that.company_id,
          ids: that.selectedList,
        })
        .then( res => {
          if( res.data.status == 0 ){
            that.openDoorDetails = res.data.result;
            Indicator.close();
          }
        })
        .catch( error => {

        })
      },
      openDoor(id){
        let that = this;
        Indicator.open({
            spinnerType: 'fading-circle'
        });
        that.$Axios.post('api_cabinet/Deliverorder/openDoorAgain',{
          mobile: that.mobile,
          company_id: that.company_id,
          id: id,
        })
        .then( res => {
          if( res.data.status == 0 ){
            Indicator.close();
            // that.openDoorDetails = res.data.result;
          }
        })
        .catch( error => {

        })
      },
      // 关闭页面
      backEvent () {
        native.goBack()
      },
    },
    mounted(){
      let that = this;
      Indicator.open({
          spinnerType: 'fading-circle'
      });
      that.getOrderDetails()
      
    }
  }
</script>
