import Vue from 'vue'

import App from './App.vue'
import router from './router'
import '@/assets/css/style.css'
import '@/assets/js/mobile.js'
import axios from 'axios'
import 'mint-ui/lib/style.css'
import mintUi from 'mint-ui'


Vue.config.productionTip = false;


Vue.prototype.$Axios = axios;
Vue.use(mintUi)
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

if(location.host == 'express.ringtop.biz'){
    //axios.defaults.baseURL = 'http://express.admin.modoubox.com';
    // axios.defaults.baseURL = 'http://xintian.modoubox.com';
    axios.defaults.baseURL = 'https://admin.xintiangui.com';
    // window.Url = 'http://xintian.modoubox.com';
    // console.log('url，xintian',window.Url)
}else{
    // axios.defaults.baseURL = 'http://express.admin.modoubox.com';
    axios.defaults.baseURL = 'https://express-admin.xintiangui.com';
//     window.Url = 'http://express.admin.modoubox.com';
//     console.log('url，express',window.Url)
}


new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
