<template>
  <div v-show=" show ">
    <div class="top">
      <img @click="backEvent" src="../assets/img/back.png" alt="">
      <span>取件</span>
    </div>
    <div class="content" v-if=" orderList.length != 0 ">
      <div class="orderList on" @click="selected(item.id,item.fee_discount,index)" v-for=" (item,index) in orderList ">
        <div class="d1">
          <p>箱子编号 <span>{{ item.number }}</span></p>
          <p>投递时间 <span>{{ item.create_at }}</span></p>
          <p>存放时长 <span class="s1">{{ item.use_time }}</span></p>
        </div>
        <div class="d2">
          <p>费用：<span>¥{{ item.fee_discount }}</span> </p>
          <p v-if=" item.pick_discount_wechat != 1 "><span class="s1">¥{{ item.fee }}</span></p>
        </div>
      </div>
      
    </div>
    <div class="noData" v-if=" orderListLength == 0 ">
      <img src="../assets/img/empty.png" alt="">
      <p>未查询到您的快递</p>
    </div>
    <div class="sure" v-if=" orderList.length != 0 ">
      <div class="d1" v-if=" selectedList.length == 0 ">确认取件</div>
      <div class="d2" v-if=" total == 0 && selectedList.length > 0 " @click="order()">确认取件</div>
      <div class="d2" v-if=" total > 0 " @click="order()">支付并取件¥{{total}}</div>
    </div>
  </div>
</template>

<script>
  import { Indicator } from 'mint-ui';
  import { MessageBox } from 'mint-ui';
  import native from '@/assets/js/native'
  import $ from 'jquery'
  export default {
    name: 'index',
    data(){
      return{
        // deviceNo=deviceNo&receivePhone=18585863360
        mobile: this.$route.query.receivePhone,
        company_id: this.$route.query.deviceNo,
        // mobile: '18339947407',
        // company_id: '170',
        orderList:[],
        orderListLength: 1,
        selectedList:[],
        // downOrderList:[],
        // checkOrderList:[],
        fee_discount_list:[],
        total: 0,
        show: false,
        timer: null,
        orderHasFree: false,
      }
    },
    methods:{
      selected(id,fee_discount,index){
        let that = this;
        clearInterval(that.timer)
        let _index = index;
        var idIndex = null;
        // var feeIndex = null;
        if( $('.orderList').eq(_index).hasClass('on') ){
          $('.orderList').eq(_index).removeClass('on')
          for( var i in that.selectedList ){
            if( that.selectedList[i] == id ){
              idIndex = i
            }
          }
          
          console.log(fee_discount*1)
          
          that.selectedList.splice(idIndex,1)
          // that.fee_discount_list.splice(feeIndex,1)
          that.total = (that.total*100 - fee_discount*100) / 100;
          that.total = parseFloat(that.total).toFixed(2);
          // console.log(that.selectedList)
        }else{
          $('.orderList').eq(_index).addClass('on');
          clearInterval(that.timer)
          that.selectedList.push(id)
          // that.fee_discount_list.push(id)
          console.log(that.selectedList)
          that.total = (that.total*100 + fee_discount*100) / 100;
          that.total = parseFloat(that.total).toFixed(2);
        }
        
      },
      order(){
        let that = this;
        Indicator.open({
            spinnerType: 'fading-circle'
        });
        // alert('开始下单！')
        that.$Axios.post('api_cabinet/Deliverorder/payment',{
          mobile: that.mobile,
          // company_id: that.company_id,
          ids: that.selectedList,
          fee: that.total,
        })
        .then( res => {

          if( res.data.status == 2 ){
            that.selectedList = that.selectedList.toString()
            that.$router.push({path:'/openDoorList',query:{receivePhone:that.mobile,deviceNo: that.company_id,orderList: that.selectedList}})
          }else if( res.data.status == 1 ){
            Indicator.close();
            MessageBox({
              title: '提示',
              message: res.data.msg,
            });
          }else if( res.data.status == 0 ){
            let params = {
              MerNo: res.data.result.MerNo, // 商户号(MerNo)
              Sign: res.data.result.Sign, // 加签字符串(Sign)
              Token: res.data.result.Token, // Token(Token)
              AppNo: res.data.result.AppNo, // 应用编号(AppNo)
              SignType: res.data.result.signType // 加签类型(SignType)
            }
            
            native.gotoPayment(JSON.stringify(params))
            Indicator.close();
            
            that.timer = setInterval(function(){
              that.$Axios.post('api_cabinet/Deliverorder/queryOrderStatus',{
                mobile: that.mobile,
                ids: that.selectedList,
              })
              .then( res => {
                if( res.data.status == 0 ){
                  clearInterval(that.timer)
                  that.selectedList = that.selectedList.toString()
                  that.$router.push({path:'/openDoorList',query:{receivePhone:that.mobile,deviceNo: that.company_id,orderList: that.selectedList}})
                }else if( res.data.status == 1 ){
                  // Indicator.open({
                  //     spinnerType: 'fading-circle'
                  // });
                }else if( res.data.status == 2 ){
                  clearInterval(that.timer)
                }else if( res.data.status == 3 ){
                  clearInterval(that.timer)
                  that.selectedList = that.selectedList.toString()
                  that.$router.push({path:'/openDoorList',query:{receivePhone:that.mobile,deviceNo: that.company_id,orderList: that.selectedList}})
                }else if( res.data.status == 10 ){
                  clearInterval(that.timer)
                }
              })
              .catch( error => {

              })
            },1000)
            
            // 支付回调 0:成功 2:取消
            // window.android.payResult(res)
          }
        })
        .catch( error => {

        })
        // that.$router.push({path:'/openDoorList',query:{receivePhone:that.mobile,deviceNo: that.company_id}})
      },
      // 回调
      // payResult (res) {
      //   console.log('payResult => ', res)
      // },
      getOrderList(){
        let that = this;
        that.selectedList = [];
        that.$Axios.post('api_cabinet/Deliverorder/getOrderList',{
          mobile: that.mobile,
          company_id: that.company_id,
        })
        .then( res => {
          if( res.data.status == 0 ){
            that.orderList = res.data.result;
            that.orderListLength = res.data.result.length;
            // 默认选中全部
            for( var i in that.orderList ){
              that.selectedList.push(that.orderList[i].id)
              // 总金额
              that.total = (that.total*100 + that.orderList[i].fee_discount*100) / 100;
              
            }
            that.total = parseFloat(that.total).toFixed(2);
            console.log(that.total)
            console.log(that.selectedList)
            Indicator.close();
          }else if( res.data.status == 1 ){
            Indicator.close();
            MessageBox({
              title: '提示',
              message: res.data.msg,
            });
          }
        })
        .catch( error => {

        })
      },
      backEvent () {
        native.goBack()
      },
    },
    mounted(){
      let that = this;
      Indicator.open({
          spinnerType: 'fading-circle'
      });
      var ua = navigator.userAgent.toLowerCase()
      // 微信浏览器或QQ打开
      if ( ua.indexOf("micromessenger") > -1 || ua.indexOf("alipayclient") > -1 ) {
        alert('请使用黔农云APP扫码取件！')  
        // location.href = 'https://iot.xintiangui.com/web_wechat/download_app_qny';
        location.href = 'https://userapp.gznxqny.com/app/appearance/downCode';
        
        
      } else {
        that.show = true;
        // alert(window.location.href)
        that.getOrderList()
      }
      
    }
  }
</script>
