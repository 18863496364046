<template>
  <div class="beijing">
    <div class="top">
      <img @click="backEvent" src="../assets/img/back.png" alt="">
      <span>我的快递</span>
    </div>
    <div class="tab">
      <p :class=" !show ? 'on':''" @click=" show = false ">待取包裹<span></span></p>
      <p :class=" show ? 'on':''" @click=" show = true ">取件记录<span></span></p>
    </div>
    <div class="content" v-if=" unTakeList.length != 0 && show == false ">
      <div class="orderList" v-for=" (item,index) in unTakeList ">
        <div class="d1">
          <p class="p1">待取包裹</p>
          <p>柜机地址 <span>{{ item.company }}</span></p>
          <p>箱子编号 <span>{{ item.door }}</span></p>
          <p>投递时间 <span>{{ item.create_at }}</span></p>
          <p>存放时间 <span>{{ item.keep_time }}</span></p>
          <p>取件码 <span class="s1">{{ item.code }}</span></p>
          <p>黔农云扫码取件费用 <span class="s1">¥{{ item.fee_qrcode/100 }}</span></p>
          <p>短信取件码取件费用 <span class="s1">¥{{ item.fee_code/100 }}</span></p>
        </div>
      </div>
    </div>
    <div class="noData" v-if=" unTakeListLength == 0 && show == false ">
      <img src="../assets/img/nodata.png" alt="">
      <p>未查询到您的快递</p>
    </div>

    <div class="content" v-if=" takeList.length != 0 && show == true ">
      <div class="orderList" v-for=" (item,index) in takeList ">
        <div class="d1">
          <p class="p1">已取包裹</p>
          <p>柜机地址 <span>{{ item.company }}</span></p>
          <p>箱子编号 <span>{{ item.door }}</span></p>
          <p>投递时间 <span>{{ item.create_at }}</span></p>
          <p>存放时间 <span>{{ item.keep_time }}</span></p>
          <p>取件时间 <span>{{ item.pick_at }}</span></p>
          <p>取件方式 <span>{{ item.done_from_text == 'APP取件' ? '扫码取件' : item.done_from_text }}</span></p>
          <p>费用 <span>¥{{ item.fee_user/100 }}</span></p>
        </div>
      </div>
    </div>
    <div class="noData" v-if=" takeListLength == 0 && show == true ">
      <img src="../assets/img/nodata.png" alt="">
      <p>未查询到您的快递</p>
    </div>
  </div>
</template>

<script>
  import { Indicator } from 'mint-ui';
  import { MessageBox } from 'mint-ui';
  import native from '@/assets/js/native'
  // import $ from 'jquery'
  export default {
    name: 'index',
    data(){
      return{
        // deviceNo=deviceNo&receivePhone=18585863360
        // mobile: this.$route.query.receivePhone,
        // company_id: this.$route.query.deviceNo,
        mobile: '',
        // mobile: '13984809597',
        unTakeList:[],
        unTakeListLength: 1,
        takeList:[],
        takeListLength: 1,
        show: false,
      }
    },
    methods:{
     
      
      getUntakeList(){
        let that = this;
        that.$Axios.post('/api_cabinet/deliverorder/queryAllOrder',{
          mobile: that.mobile,
          is_done: 0,
          sub_mercode: that.$route.query.sub_mercode,
          // sub_mercode: '806701572990005'
        })
        .then( res => {
          if( res.data.status == 0 ){
            that.unTakeList = res.data.result;
            that.unTakeListLength = res.data.result.length;
            Indicator.close();
          }else if( res.data.status == 1 ){
            Indicator.close();
            MessageBox({
              title: '提示',
              message: res.data.msg,
            });
          }
        })
        .catch( error => {

        })
      },
      getTakeList(){
        let that = this;
        that.$Axios.post('/api_cabinet/deliverorder/queryAllOrder',{
          mobile: that.mobile,
          is_done: 1,
          sub_mercode: that.$route.query.sub_mercode,
          // sub_mercode: '806701572990005',
        })
        .then( res => {
          if( res.data.status == 0 ){
            that.takeList = res.data.result;
            that.takeListLength = res.data.result.length;
            Indicator.close();
          }else if( res.data.status == 1 ){
            Indicator.close();
            MessageBox({
              title: '提示',
              message: res.data.msg,
            });
          }
        })
        .catch( error => {

        })
      },
      backEvent () {
        native.goBack()
      },
      // 支付回调 0:成功 2:取消
      userInfo (res) {
        let that = this;
        let obj = JSON.parse(res)
        that.mobile = obj.Phone
        that.getUntakeList()
        that.getTakeList()
      },
    },
    mounted(){
      let that = this;
      console.log('参数:'+that.$route.query.sub_mercode);
      // 获取用户信息回调
      window.userInfo = that.userInfo
      native.getUserInfo();
      
      // Indicator.open({
      //     spinnerType: 'fading-circle'
      // });
      // var ua = navigator.userAgent.toLowerCase()
      // // 微信浏览器或QQ打开
      // if ( ua.indexOf("micromessenger") > -1 || ua.indexOf("alipayclient") > -1 ) {
      //   alert('请使用黔农云APP扫码取件！')  
      //   // location.href = 'https://iot.xintiangui.com/web_wechat/download_app_qny';
      //   location.href = 'https://userapp.gznxqny.com/app/appearance/downCode';
        
        
      // } else {
        // that.show = true;
        // alert(window.location.href)
        
      // }
      
    }
  }
</script>
<style scoped>
    .content {
        padding: 2rem 0 1rem 0;
        /* width: 6.9rem; */
        
        /* margin: 2rem auto 0; */
        border-radius: .15rem;
    }
    .content>div {
        margin: 0 auto;
        padding-bottom: .2rem;
        border-radius: 0;
        background: rgba(255, 255, 255, 1);
        background-size: 0;
        border-bottom: 1px solid #eee;
    }
    .content>div .d1:nth-last-of-type(1){
        padding: .3rem 0;
        border-bottom: 0px solid #f5f5f5;
    }
    .content>div .d1 p.p1{
      margin: 10px 0;
      color: #333;
      font-size: .4rem;
    }
    .content>div .d1 p span{
      float: right;
    }
    .content>div .d1 p span.s1{
        color: #1fb18d;
        width: 2.5rem;
    }
    .tab{
        width: 100%;
        height: .8rem;
        background-color: #fff;
        position: fixed;
        top: .8rem;
        left: 0;
        text-align: center;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
        text-align: center;
        z-index: 10;
    }
    .tab p{
        width: 40%;
        display: inline-block;
        font-size: .34rem;
        color: #333;
        line-height: .8rem;
        position: relative;
    }
    .tab p.on{
        display: inline-block;
        font-size: .34rem;
        color: #1fb18d;
        line-height: .8rem;
    }
    .tab p.on span{
        width: 1rem;
        height: .08rem;
        background-color: #1fb18d;
        border-radius: .04rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -.5rem;
    }
</style>