// 公共js
// !(function(doc, win) {
// 	var docEle = doc.documentElement, //获取html元素
// 		event = "onorientationchange" in win ? "onorientationchange" : "risize", //判断是屏幕旋转还是resize
// 		fn = function() {
// 			if(event == "onorientationchange") {
// 				var domTimeout = setTimeout(function() {
// 					var width = docEle.clientWidth < 1080 ? docEle.clientWidth : 1080;
// 					width && (docEle.style.fontSize = 100 * (width / 750) + 'px'); //设置html的fontSize, 随着event的改变而改变.
// 					domTimeout = null;
// 				}, 25)
// 			} else {
// 				var width = docEle.clientWidth < 1080 ? docEle.clientWidth : 1080;
// 				width && (docEle.style.fontSize = 100 * (width / 750) + 'px'); //设置html的fontSize, 随着event的改变而改变.
// 			}
// 		}
// 	win.addEventListener(event, fn, false);
// 	doc.addEventListener("DOMContentLoaded", fn, false);
// }(document, window));

//designWidth:设计稿的实际宽度值，需要根据实际设置
//maxWidth:制作稿的最大宽度值，需要根据实际设置
//这段js的最后面有两个参数记得要设置，一个为设计稿实际宽度，一个为制作稿最大宽度，例如设计稿为750，最大宽度为750，则为(750,750)


(function(designWidth, maxWidth) {
    var doc = document,
    win = window,
    docEl = doc.documentElement,
    remStyle = document.createElement("style"),
    tid;

    function refreshRem() {
        var width = docEl.getBoundingClientRect().width;
        maxWidth = maxWidth || 540;
        width>maxWidth && (width=maxWidth);
        var rem = width * 100 / designWidth;
        remStyle.innerHTML = 'html{font-size:' + rem + 'px;}';
    }

    if (docEl.firstElementChild) {
        docEl.firstElementChild.appendChild(remStyle);
    } else {
        var wrap = doc.createElement("div");
        wrap.appendChild(remStyle);
        doc.write(wrap.innerHTML);
        wrap = null;
    }
    //要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
    refreshRem();

    win.addEventListener("resize", function() {
        clearTimeout(tid); //防止执行两次
        tid = setTimeout(refreshRem, 300);
    }, false);

    win.addEventListener("pageshow", function(e) {
        if (e.persisted) { // 浏览器后退的时候重新计算
            clearTimeout(tid);
            tid = setTimeout(refreshRem, 300);
        }
    }, false);

    if (doc.readyState === "complete") {
        doc.body.style.fontSize = "16px";
    } else {
        doc.addEventListener("DOMContentLoaded", function(e) {
            doc.body.style.fontSize = "16px";
        }, false);
    }
})(750, 750);